import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd, showSnackbar } from "../../redux/actions";

// MaterialUI et Layout
import { DataGrid, GridRowsProp, GridColDef, GridSortModel, getGridStringOperators, getGridDateOperators} from "@mui/x-data-grid";
import { Card, Alert, Button} from "@mui/material";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import IconButton from "@mui/material/IconButton";

// Fetchs
import { getAll, getWithFilter } from "../../fetchs/get";
import { deleteOne } from "../../fetchs/delete";
import { fetchPostProtect } from "../../fetchs/post";

import { IHosting } from "../../interface/hosting";
import { useNavigate } from "react-router-dom";
import AlerteDialog from "../../components/AlerteDialog/AlerteDialog";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { Search, SearchIconWrapper, StyledInputBase } from "../../tools/listHosting";
import CustomHostingToolbar from "../../components/Hostings/HostingCustomToolBar";

const ListHostings = () => {
  const [hostings, setHostings] = useState<IHosting[]>([]);
  const [error, setError] = useState("");
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmCopy, setOpenConfirmCopy] = useState(false);
  const [hostingId, setHostingId] = useState();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "createdAt",
      sort: "desc",
    },
  ]);

  let dispatch = useDispatch();
  let navigate = useNavigate();

  let token = useSelector((state: RootState) => {
    return state.user.token;
  });

  const getAllHostings = () => {
    if (token) {
      getAll(token, "/hosting/", setHostings, 
        () => {
          dispatch(sessionEnd());
        }, setError
      );
    }
  };

  useEffect(() => {
    getAllHostings();
  }, []);

  const handleClickOpenDelete = (id: any) => {
    setHostingId(id);
    setOpenConfirmDelete(true);
  };

  const handleClickOpenDuplicate = (id: any) => {
    setHostingId(id);
    setOpenConfirmCopy(true);
    return id;
  };

  const handleClose = () => {
    setOpenConfirmDelete(false);
    setOpenConfirmCopy(false);
  };

  const duplicateHosting = () => {
    if (token && hostingId) {
      const hosting = hostings.find((h: IHosting) => h._id === hostingId);
      if (hosting) {
        const requestBody = JSON.stringify({
          name: hosting.name,
          _id: hosting._id
        })
        fetchPostProtect(token, "/hosting/duplicate", requestBody)
          .then((res) => {
            setOpenConfirmCopy(false);
            dispatch(showSnackbar("Hébergement dupliqué avec succès !", "success"));
            if (res.status === 200 && token) {
              getAll(token, "/hosting/", setHostings, () => dispatch(sessionEnd()), setError);
            } else if (res.status === 401) {
              dispatch(sessionEnd());
            } else if (res.status === 404 && token) {
              getAll(token, "/hosting/", setHostings, () => dispatch(sessionEnd()), setError);
              res.json().then((err) => {
                setError(err.error);
                dispatch(showSnackbar("Erreur lors de la duplication du projet : " + err.error, "error"));
              });
            }
          })
          .catch((err) => setError(err));
      } else {
        dispatch(showSnackbar("Erreur lors de la duplication de l'hébergement : Id de l'hébergement introuvable", "error"));
      }
    }
  };

  const deleteHosting= () => {
    let id = hostingId;
    if (token) {
      deleteOne(token, "/hosting/", id).then((res) => {
        if (res.status === 200) {
          dispatch(showSnackbar("Hébergement supprimé avec succès !", "success"));
          getAllHostings();
        } else if (res.status === 401) {
          dispatch(sessionEnd());
        } else {
          res.json().then((err) => {
            setError(err.error);
            dispatch(showSnackbar("Erreur lors de la suppression du projet : " + err.error, "error"));
          });
        }
      });
    }
    handleClose();
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nom",
      flex: 2,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
    },
    {
      field: "client",
      headerName: "Client",
      flex: 2,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
    },
    {
      field: "type",
      headerName: "Type d'hébergement",
      flex: 2,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
    },
    {
      field: "author",
      headerName: "Auteur",
      flex: 2,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
    },
    {
      field: "createdAt",
      headerName: "Crée le",
      flex: 1,
      type: "date",
      valueGetter: (params) => new Date(params.row.createdAt.split("/").reverse().join("-")),
      filterable: true,
      filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === "is" || operator.value === "onOrAfter" || operator.value === "onOrBefore"
      ),
    },
    {
      field: "updatedAt",
      headerName: "Mis à jour le",
      flex: 1,
      type: "date",
      valueGetter: (params) => new Date(params.row.updatedAt.split("/").reverse().join("-")),
      filterable: true,
      filterOperators: getGridDateOperators().filter(
        (operator) => operator.value === "is" || operator.value === "onOrAfter" || operator.value === "onOrBefore"
      ),
    },
    {
      field: "copy",
      headerName: "Dupliquer",
      cellClassName: "center-cell",
      renderCell: () => {
        return (
          <IconButton>
            <ContentCopyTwoToneIcon sx={{ color: "orange" }} />
          </IconButton>
        );
      },
      filterable: false,
    },
    {
      field: "delete",
      headerName: "Supprimer",
      cellClassName: "center-cell",
      renderCell: () => {
        return (
          <IconButton>
            <DeleteTwoToneIcon sx={{ color: "red" }} />
          </IconButton>
        );
      },
      filterable: false,
    },
  ];

  const rows: GridRowsProp = hostings.map((hosting: IHosting) => {
    return {
      id: hosting._id,
      name: hosting.name,
      client: hosting.client ? hosting.client.name : "",
      type: hosting.type === "classic" ? "Classique" : "Personnalisé",
      author: hosting.author,
      createdAt: hosting.createdAt,
      updatedAt: hosting.updatedAt,
    };
  });

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 8,
    page: 0,
  });

  const loadMoreHostings = () => {
    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      pageSize: prevPaginationModel.pageSize + hostings.length >= 99 ? 99 : prevPaginationModel.pageSize + hostings.length
    }));
  };

  return (
    <div className="listProjects">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "block" },
              }}
            >
              Liste des hébergements
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Card
        elevation={0}
        component="main"
        sx={{
          backgroundColor: "white",
          m: 2,
          p: 2,
          borderRadius: "10px",
          height: "calc(100vh - 164px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Button variant="contained" color="primary" sx={{ display: 'flex', justifyContent: 'flex-start', color: 'primary' }} onClick={loadMoreHostings} disabled={paginationModel.pageSize >= hostings.length}>
            Voir tout les hébergements
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Recherche d'un hébergement"
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => {
                  setTimeout(() => {
                    if (token) {
                      getWithFilter(
                        token,
                        "/hosting/filter/",
                        e.target.value,
                        setHostings,
                        () => {
                          dispatch(sessionEnd());
                        },
                        setError
                      );
                    }
                  }, 500);
                }}
              />
            </Search>
          </Box>
        </Box>
        {error ? <Alert severity="error">{error}</Alert> : null}

        <DataGrid
          disableRowSelectionOnClick={true}
          rows={rows}
          columns={columns}
          paginationModel={paginationModel}
          pageSizeOptions={[8]}
          onPaginationModelChange={setPaginationModel}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          components={{
            Toolbar: CustomHostingToolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          sx={{
            "& .MuiDataGrid-row": {
              cursor: "pointer",
            },
            height: "100%",
          }}
          onCellClick={(row) => {
            if (row.field === "delete") {
              handleClickOpenDelete(row.id);
            } else if (row.field === "copy") {
              handleClickOpenDuplicate(row.id);
            } else{
              navigate(`/hebergement/${row.id}`);
            }
          }}
        />
      </Card>
      {openConfirmDelete && (
        <AlerteDialog
          open={openConfirmDelete}
          handleClose={handleClose}
          deleteProject={deleteHosting}
          title={"Souhaitez vous vraiment supprimer cet hébergement ?"}
          contentText={
            "Attention vous êtes sur le point de supprimer définitivement cet hébergement. Cette action est irréversible"
          }
        />
      )}
      {openConfirmCopy && (
        <AlerteDialog
          open={openConfirmCopy}
          handleClose={handleClose}
          deleteProject={duplicateHosting}
          title={"Dupliquer l'hébergement'"}
          contentText={"Souhaitez vous dupliquer cet hébergement ?"}
        />
      )}
    </div>
  );
};

export default ListHostings;
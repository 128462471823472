import { useCallback, useEffect, useState } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd, showSnackbar } from "../../redux/actions/index";

// MaterialUI
import { Card, Alert, Typography, Box } from "@mui/material";
import NavTab from "../../layout/NavTab";

// React router
import { useNavigate } from "react-router-dom";

// Fetch
import { fetchPostProtect } from "../../fetchs/post";
import AddUpdateHosting from "../../components/Hostings/AddUpdateHosting";

import { getAll } from "../../fetchs/get";
import addUpdateHostingData from "../../tools/addUpdateHosting";
import { PriceData } from "../../interface/hosting";

interface IFormValues {
  name: string;
  description: string;
  client: string;
  environment: string;
  margin: number;
  retentionTime: number;
  storage: number;
  servers: [string];
  nbIP: number;
}

const AddHosting = () => {
    const [error, setError] = useState<string>("");

    const [prices, setPrices] = useState<PriceData[]>([]);

    const [typeChecked, setTypeChecked] = useState<boolean>(false);

    let dispatch = useDispatch();
    let navigate = useNavigate();

    // Permet de récupérer le token sauvegarder dans le redux
    let token = useSelector((state: RootState) => {
      return state.user.token;
    });

    // Permet de récupérer l'username sauvegarder dans le redux
    let author = useSelector((state: RootState) => {
      return state.user.username;
    });

    const getPrices = useCallback(() => {
      if (token) {
        getAll( token, "/hosting/ovh", setPrices,
          () => {
            dispatch(sessionEnd());
          },
          setError
        );
      }
    }, []);

    useEffect(() => {
      getPrices();
    }, [getPrices]);

    const addHosting = async (useless : string, values: IFormValues) => {

      const body = await addUpdateHostingData(values, prices, undefined, typeChecked, author);

      if(!body){
        dispatch(showSnackbar("Un serveur NFS ne peut pas avoir 0 Go de stockage !", "error"));
      }
      else{
        if (token) {
          await fetchPostProtect(token, "/hosting/add", body)
            .then((response) => {
              if (response.status === 200) {
                setError("");
                response
                  .json()
                  .then((result) => {
                    navigate(`/hebergement/${result._id}`, { state: "hostingSuccess" });
                  })
                  .catch((err) => setError(err));
              } else if (response.status === 401) {
                dispatch(sessionEnd());
              } else {
                response.json().then((result) => setError(result.message));
              }
            })
            .catch((err) => setError(err));
        }
      }      
    };

    return (
      <div className="addProject">
        <NavTab text="Ajouter un hébergement" showDialog={true} />
        <Card
          elevation={0}
          component="main"
          sx={{
            backgroundColor: "rgba(255,255,255,0.45)",
            m: 2,
            p: 2,
            borderRadius: "10px",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            overflowY: 'auto',
          }}
        >
          <Box sx={{ height: "fit-content", width: "800px",
              border: "1px solid rgba(0,0,0,0.2)",
              borderRadius: "10px",
              padding: "30px",
              backgroundColor: "white",
          }}>
          <Typography variant="subtitle2" component="div" align="center">
              Merci de renseigner les champs pour l'ajout d'un hébergement
          </Typography>
          {error ? <Alert severity="error">{error}</Alert> : null}

          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "flex_start",
            }}
          >
            <AddUpdateHosting 
              handleSubmit={addHosting} 
              edit={false} 
              isSwitched={false} 
              typeChecked={typeChecked}
              setTypeChecked={setTypeChecked}
            />
          </div>
          </Box>
        </Card>
      </div>
    );
  };
export default AddHosting
import { Button } from "@mui/material";
import { useState } from "react";
import AlerteDialog from "../../components/AlerteDialog/AlerteDialog";

const AddLotButton = ({
    setOpenModal
}: {
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const [openWarningModal, setOpenWarningModal] = useState<boolean>(false);

    return (
        <>
            <Button
                variant="contained"
                color="tertiary"
                sx={{ mr: 2 }}
                onClick={() => {
                    setOpenWarningModal(true);
                }}
            >
                Ajouter un lot
            </Button>
            {openWarningModal && (
                <AlerteDialog
                open={openWarningModal}
                handleClose={() => setOpenWarningModal(false)}
                deleteProject={() => {
                    setOpenWarningModal(false)
                    setOpenModal(true)
                }}
                title={"ATTENTION : toute modification sur le lot non enregistrée sera perdue !"}
                contentText={"Voulez-vous vraiment quitter la tâche en cours sans sauvergarder ?"}
              />
            )}
        </>
    )
}

export default AddLotButton
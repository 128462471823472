import React from "react";
import { useEffect, useState } from "react";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import { useNavigate } from "react-router-dom";

//Material UI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Tooltip } from "@mui/material";
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Fade } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

//Interface
import { ITask } from "../../interface/task";
import { IModal } from "../../interface/task";

// Websocket

// import { io } from "socket.io-client";
// import { wsURL } from "../../config/config";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { useDispatch } from "react-redux";
import {
  addTask,
  changeValue,
  sessionEnd,
  initTasks,
  delete_tasks,
  calculAll,
  calculTCosts
} from "../../redux/actions/index";
import { ILot } from "../../interface/lot";
import { getOne } from "../../fetchs/get";
import { fetchPutProtect } from "../../fetchs/put";

import useWindowDimensions from "../../tools/useWindowDimensions";
import AlerteDialog from "../AlerteDialog/AlerteDialog";
import { useIdle } from "@uidotdev/usehooks";

const TaskDetails = ({
  lotId,
  setLotId,
  modal,
  onSave,
  showDialog,
  setShowDialog,
  updateLot,
  setValidDelete
}: {
  lotId: string;
  setLotId: Function;
  modal: boolean;
  onSave: boolean;
  showDialog: boolean;
  updateLot: Function
  setShowDialog: Function;
  setValidDelete: Function;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalId, setModalId] = useState<IModal | undefined>(undefined);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog, setLotId);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [openIdleModal, setOpenIdleModal] = useState(false);
  const [userActive, setUserActive] = useState(false);

  const { width } = useWindowDimensions();

  let tasks: Array<ITask> = useSelector((state: RootState) => {
    return state.lotSynthesis.tasks;
  });

  let token = useSelector((state: RootState) => {
    return state.user.token;
  });

  // const idle = useIdle(1800000);

  let dispatch = useDispatch();
  let navigate = useNavigate();

  // useEffect(() => {
  //   let timeout: NodeJS.Timeout;
  //   if (idle && !userActive) {
  //     setOpenIdleModal(true);
  //     timeout = setTimeout(() => {
  //       updateLot()
  //       setOpenIdleModal(false);
  //       navigate("/projets");
  //     }, 10000);
  //   }
  //   return () => clearTimeout(timeout);
  // }, [idle, userActive, navigate]);

  // Socket IO
  // useEffect(() => {
  //   console.log("ici ici ici", wsURL)
  //   const socket = io(wsURL, { transports: ['websocket', 'polling'] });

  //   const projetId = (window.location.pathname).slice(8);
  //   let test = socket.emit("projectLock", projetId); // dès que j'arrive sur la page

  //   return () => {
  //     let test2 = socket.emit("projectUnlock", projetId); // dès que je quitte la page
  //     socket.disconnect();
  //   };
  // }, []);

  // const resetIdle = () => {
  //   setUserActive(false);
  // };

  useEffect(() => {
    getLot();
  }, [lotId]);

  useEffect(() => {
    if (tasks.length === 0) {
      dispatch(addTask());
      dispatch(calculAll());
      dispatch(calculTCosts());
    }
  }, [tasks]);

  useEffect(() => {
    if (onSave) {
      setShowDialog(false);
    }
  }, [onSave]);

  /**
   * @param lot
   * Permet de récupérer et d'afficher les tâches déjà créées d'un lot
   */
  const putLotAndTask = (lot: ILot) => {
    dispatch(initTasks(lot.tasks));
  };
  const getLot = () => {
    if (token) {
      getOne(token, "/lots/", lotId, putLotAndTask, () => {
        dispatch(sessionEnd());
      });
    }
  };

  const handleAction = (e: any, taskId: string | number) => {
    setTimeout(() => {
      if (e.target.name === "RTUD" || e.target.name === "RTUI") {
        dispatch(calculAll());
        dispatch(calculTCosts());
        setShowDialog(true);
      } else if (e.target.name === "G") {
        dispatch(calculTCosts());
        setShowDialog(true);
      } else if (e.target.name !== "description" || e.target.name !== "name") {
        dispatch(calculTCosts());
        setShowDialog(true);
      }
    }, 1);
  };
  /**
   * @param lotId
   * @param taskId index du mappage des taches
   * Supprime la tâche du store redux en fonction de l'index de la tâche
   * set les tâches du store pour mettre à jour le tableau
   */
  const deleteTask = (index: number) => {
    if (token) {
      fetchPutProtect(token, `/lots/${lotId}/task/${tasks[index]._id}`, null)
        .then(() => {
          // Dispatch an action to save the changes before deleting the task
          dispatch(changeValue(0, "A", tasks[index]._id));
          dispatch(changeValue(0, "RTUD", tasks[index]._id));
          dispatch(changeValue(0, "RTUI", tasks[index]._id));
          dispatch(calculAll());
          dispatch(calculTCosts());
          // Wait for a short duration before deleting the task
          setTimeout(() => {
            dispatch(delete_tasks(index, tasks));
            setShowDialog(true);
            setConfirmDelete(false);
            setValidDelete(true)
          }, 1)
        })
        .catch((error) => {
          console.error("Error deleting task:", error);
        });
    }
  };


  const numberInputOnWheelPreventChange = (e: any) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  /**
   * Modal pour modifier le nom et la description d'une tâche
   */
  const ModalNameDescription = () => {
    const [name, setName] = useState<string>(modalId?.task ? tasks[modalId?.task - 1]?.name : "");
    const [description, setDescription] = useState<string>(modalId?.task ? tasks[modalId?.task - 1]?.description : "");

    return (
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        closeAfterTransition
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={showModal}>
          <Box className="tasksModal">
            <Typography id="modal-modal-title" variant="h5" component="h2" mb={2}>
              {`Tâche ${modalId?.task}`}
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2" mb={2}>
              Nom
            </Typography>
            <TextField
              id="outlined-textarea"
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              label={"Nom de la tâche"}
              variant="filled"
              style={{ width: "100%" }}
              type="text"
              defaultValue={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              name={"name"}
              InputProps={{
                inputProps: {
                  inputMode: "numeric",
                  style: {
                    padding: 5,
                  },
                  min: 0,
                },
              }}
            />
            <Typography id="modal-modal-title" variant="h6" component="h2" my={2}>
              Description
            </Typography>
            <TextField
              id="outlined-textarea"
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              label={"Description de la tâche"}
              variant="filled"
              style={{ width: "100%", maxHeight: "500px", overflow: "scroll" }}
              type="text"
              defaultValue={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              name={"description"}
              InputProps={{
                inputProps: {
                  inputMode: "numeric",
                  style: {
                    padding: 5,
                  },
                  min: 0,
                },
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "end", mt: 4 }}>
              <Button
                variant="contained"
                onClick={() => {
                  setShowModal(false);
                  dispatch(changeValue(name, "name", modalId?.task));
                  dispatch(changeValue(description, "description", modalId?.task));
                }}
              >
                Valider
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    );
  };

  /**
   * @param task
   * @returns les données des tâches dans les cellules du tableau
   */
  const displayCell = (task: ITask) =>
    Object.keys(task).map((key) => {
      let element = task[key];
      let buttonDisabled =
        key !== "name" && key !== "description" && key !== "RTUD" && key !== "RTUI" && key !== "A" ? true : false;
      if (key !== "_id" && key !== "PPR" && key !== "description") {
        return (
          <TableCell
            key={key}
            sx={{
              padding: 0.3,
            }}
          >
            {key === "name" ? (
              <Box
                onClick={() => {
                  setShowModal(true);
                  setModalId({ key: key, task: task._id });
                }}
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: "5px",
                  padding: "0.2rem",
                  cursor: "pointer",
                }}
              >
                <Tooltip title={element ? element : ""}>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      paddingLeft: "0.2rem",
                      maxWidth: "140px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {element}
                  </Typography>
                </Tooltip>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography color="inherit">Description</Typography>
                      {task.description}
                    </React.Fragment>
                  }
                >
                  <InfoIcon
                    sx={{
                      opacity: 0.25,
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.opacity = "1";
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.opacity = "0.25";
                    }}
                  ></InfoIcon>
                </HtmlTooltip>
              </Box>
            ) : (
              <TextField
                key={key}
                id="filled-basic"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                style={{ width: "100%" }}
                type="text"
                disabled={buttonDisabled}
                value={element}
                onChange={(e) => {
                  if (/^([0-9]+([\.,][0-9]*)?)?$/.test(e.target.value)) {
                    dispatch(changeValue(e.target.value, e.target.name, task._id));
                  } else {
                    e.target.value = e.target.value.slice(0, -1);
                  }
                }}
                onKeyDown={(e: any) => {
                  handleAction(e, task._id);
                }}
                onBlur={(e: any) => {
                  handleAction(e, task._id);
                }}
                onWheel={numberInputOnWheelPreventChange}
                name={key}
                InputProps={{
                  inputProps: {
                    inputMode: "numeric",
                    style: {
                      padding: 5,
                    },
                  },
                }}
              />
            )}
          </TableCell>
        );
      }
    });

  return (
    <div className="tasksTableContainer">
      <AlerteDialog
        open={showPrompt as boolean}
        handleClose={cancelNavigation}
        deleteProject={confirmNavigation}
        title={"Les données du lot ont été modifiées"}
        contentText={"Souhaitez-vous vraiment quitter cette page sans enregistrer ?"}
      />
      <AlerteDialog
        open={confirmDelete}
        handleClose={() => {
          setConfirmDelete(false);
        }}
        deleteProject={() => deleteTask(deleteId)}
        title={"Supprimer définitivement la tâche"}
        contentText={"Souhaitez-vous vraiment supprimer cette tâche ? Cette action est irréversible et sera sauvegardée."}
        color="error"
      />
      <ModalNameDescription />
      <TableContainer sx={{ mt: 1 }} style={{ overflowY: "scroll" }} >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#F0F0F0" }}>
            <TableRow>
              <Tooltip placement="top" arrow title="Nom de la tâche">
                <TableCell sx={{ padding: "10px", width: "180px" }}>Tâches & Description</TableCell>
              </Tooltip>
              <Tooltip placement="top" arrow title="CONCEPTION">
                <TableCell sx={{ padding: "10px" }}>Concept.</TableCell>
              </Tooltip>
              <Tooltip placement="top" arrow title="REALISATION Développements">
                <TableCell sx={{ padding: "10px" }}>Real/Dev</TableCell>
              </Tooltip>
              <Tooltip placement="top" arrow title="REALISATION Infrastructures">
                <TableCell sx={{ padding: "10px" }}>Real/Infra</TableCell>
              </Tooltip>
              <Tooltip placement="top" arrow title="QUALIFICATION Stratégie conception">
                <TableCell sx={{ padding: "10px" }}>Qual/Stra</TableCell>
              </Tooltip>
              <Tooltip placement="top" arrow title="QUALIFICATION Conception cas de tests">
                <TableCell sx={{ padding: "10px" }}>Qual/CCT</TableCell>
              </Tooltip>
              <Tooltip placement="top" arrow title="QUALIFICATION Exécution">
                <TableCell sx={{ padding: "10px" }}>Qual/Exec</TableCell>
              </Tooltip>
              <Tooltip placement="top" arrow title="RECETTE Strétégie conception">
                <TableCell sx={{ padding: "10px" }}>Rec/Stra</TableCell>
              </Tooltip>
              <Tooltip placement="top" arrow title="RECETTE Conception de cas de tests">
                <TableCell sx={{ padding: "10px" }}>Rec/CCT</TableCell>
              </Tooltip>
              <Tooltip placement="top" arrow title="RECETTE Exécution">
                <TableCell sx={{ padding: "10px" }}>Rec/Exec</TableCell>
              </Tooltip>
              <Tooltip placement="top" arrow title="DEPLOIEMENT">
                <TableCell sx={{ padding: "10px" }}>Deploy.</TableCell>
              </Tooltip>
              <Tooltip placement="top" arrow title="PILOTAGE ET COORDINATION">
                <TableCell sx={{ padding: "10px" }}>Pil.&C.</TableCell>
              </Tooltip>
              <Tooltip placement="top" arrow title="ACCOMPAGNEMENT">
                <TableCell sx={{ padding: "10px" }}>Acc</TableCell>
              </Tooltip>
              <Tooltip placement="top" arrow title="GARANTIE">
                <TableCell sx={{ padding: "10px" }}>Guar.</TableCell>
              </Tooltip>
              <Tooltip placement="top" arrow title="Supprimer une tâche">
                <TableCell sx={{ padding: "10px" }}>Suppr</TableCell>
              </Tooltip>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks?.map((task: ITask, index) => (
              <TableRow key={index} sx={{ border: 0 }}>
                {displayCell(task)}
                <td>
                  {width && width < 900 ? (
                    <Tooltip placement="top" arrow title={task.name}>
                      <IconButton
                        onClick={() => {
                          setDeleteId(index);
                          setConfirmDelete(true);
                        }}
                      >
                        <DeleteTwoToneIcon sx={{ color: "red", margin: "0px 15px" }} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setDeleteId(index);
                        setConfirmDelete(true);
                      }}
                    >
                      <DeleteTwoToneIcon sx={{ color: "red", margin: "0px 15px" }} />
                    </IconButton>
                  )}
                </td>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Dialog
        open={openIdleModal}
        onClose={() => setOpenIdleModal(false)}
      >
        <DialogTitle>{"Êtes-vous toujours là ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vous semblez être inactif. Si vous ne répondez pas, vous serez redirigé vers la page de la liste des projets.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setUserActive(true);
            setOpenIdleModal(false);
            resetIdle();
          }} color="primary">
            Oui
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default TaskDetails;

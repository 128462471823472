import { GridRowsProp, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";

const CustomHostingToolbar = (
    rows : GridRowsProp
) => {
    const rowsCount = rows.length;
  
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={{ mx: 1 }} />
        <GridToolbarFilterButton sx={{ mx: 1 }} />
        
        {rowsCount <= 100 && (
          <GridToolbarExport
            sx={{ mx: 1 }}
            csvOptions={{ delimiter: ';', utf8WithBom: true, fileName: "Liste des hébergements" }}
            printOptions={{ fileName: "Liste des hébergements", hideToolbar: true }}
          />
        )}
        
        {rowsCount > 100 && (
          <GridToolbarExport
            sx={{ mx: 1 }}
            csvOptions={{ delimiter: ';', utf8WithBom: true, fileName: "Liste des hébergements" }}
            printOptions={{ disableToolbarButton: true }}
          />
        )}
        
      </GridToolbarContainer>
    );
  }

  export default CustomHostingToolbar;
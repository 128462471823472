import { useState, useEffect } from "react";

// MaterialUI
import { Card, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";

// Layout
import NavTab from "../../layout/NavTab";

// Fetch
import { getOne } from "../../fetchs/get";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd, showSnackbar } from "../../redux/actions/index";

// React router
import { useParams, useLocation } from "react-router-dom";

// Tools
import { useNavigate } from "react-router-dom";
import { IHosting } from "../../interface/hosting";
import HostingDetails from "../../components/Hostings/HostingDetails";

const Hosting = () => {
  const { state } = useLocation();
  let navigate = useNavigate();

  const [hosting, setHosting] = useState<IHosting>();
  const [error, setError] = useState("");
  const [isModified, setIsModified] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [lotId, setLotId] = useState<string>("");
  const [showDialog, setShowDialog] = useState(false);

  let token = useSelector((state: RootState) => {
    return state.user.token;
  });

  const dispatch = useDispatch();
  const params = useParams();

  const getHosting = () => {
    if (token && params.hostingId) {
      getOne( token, "/hosting/", params.hostingId, setHosting,
        () => {
          dispatch(sessionEnd());
        }, (err: any) => {
            navigate(`/hebergement/`);
            dispatch(showSnackbar("Erreur : " + err?.error, "error"));
          }
      );
    }
  };

  useEffect(() => {
    getHosting();
    if (state === "hostingSuccess") {
      dispatch(showSnackbar("Hébergement créé avec succès", "success"));
    }
  }, []);

  return (
    <Box className="projectPageContainer" component="main">
      <NavTab
        text={"Hébergement : " + (hosting?.name || "")}
        hosting={hosting}
        setOpenModal={setOpenModal}
        setLotId={setLotId}
        lotId={lotId}
        showDialog={showDialog}
        displayReturn={true}
      />

      <Card
        elevation={0}
        component="main"
        sx={{
          backgroundColor: isModified ? "rgba(255,255,255,0.45)" : "white",
          m: 2,
          p: 2,
          borderRadius: "10px",
          height: "calc(100vh - 164px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {hosting?.client ? (
          //TODO : css style scrollbar size 
          <Fade in={true} className='scrollbar' style={{
            overflow: "scroll",
            margin:'-10px',
            paddingRight:'10px'
          }}>
            <div>
              <HostingDetails 
                hosting={hosting}
                isModified= {isModified}
                setIsModified={setIsModified}
              />
            </div>
          </Fade>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            Chargement ...
            <CircularProgress />
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default Hosting;
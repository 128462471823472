// React
import { useState, useEffect, useCallback } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd, showSnackbar } from "../../redux/actions/index";

// MaterialUI
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

// Components
import { colorDarkOrange } from "../../config/color";

// Fetch
import { getAll } from "../../fetchs/get";
import { fetchPutProtect } from "../../fetchs/put";

// Icons
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { IHosting, PriceData } from "../../interface/hosting";
import HostingCostSummary from "./HostingCostSummary";
import AddUpdateHosting from "./AddUpdateHosting";
import addUpdateHostingData from "../../tools/addUpdateHosting";
import AlerteDialog from "../AlerteDialog/AlerteDialog";

interface IFormValues {
  name: string;
  description: string;
  client: string;
  environment: string;
  margin: number;
  retentionTime: number;
  storage: number;
  servers: [string];
  nbIP: number;
}

const HostingDetails = ({
  hosting,
  isModified,
  setIsModified
}: {
  hosting: IHosting;
  isModified: boolean;
  setIsModified: Function;
}) => {
  let dispatch = useDispatch();

  let token = useSelector((state: RootState) => {
    return state.user.token;
  });

  const [isSwitched, setIsSwitched] = useState<boolean>(true);
  const [isCompared, setIsCompared] = useState<boolean>(false);
  const [isRotated, setIsRotated] = useState(false);

  const [prices, setPrices] = useState<PriceData[]>([]);

  const [typeChecked, setTypeChecked] = useState<boolean>(false);

  const [openConfirmClose, setOpenConfirmClose] = useState<boolean>(false);

  const getPrices = useCallback(() => {
    if (token) {
      getAll( token, "/hosting/ovh", setPrices,
        () => {
          dispatch(sessionEnd());
        },
        setError
      );
    }
  }, []);

  useEffect(() => {
    getPrices();
  }, [getPrices]);

  const [error, setError] = useState<string>("");

  const arrowStyle = {
    float: 'right',
    position: 'absolute',
    top: "40px",
    right: "30px",
    transition: 'transform 0.5s ease',
    transform: isRotated ? 'rotate(90deg)' : 'rotate(270deg)',
    color: 'darkOrange',
    width: '40px',
    height: '40px'
  };

  const updateHosting = async (toUpdate: string, values?: IFormValues) =>  {
    const body = (toUpdate === "all" && values) ? await addUpdateHostingData(values, prices, hosting) : JSON.stringify({ _id: hosting._id, prices: prices[0], })
    const url = toUpdate === "all" ? "/hosting/update" : "/hosting/updatePrices";
    
    if (token) {
      await fetchPutProtect(token, url, body)
        .then((response) => {
          if (response.status === 200) {
            setError("");
            response
              .json()
              .then((result) => {
                toUpdate === "all" ? setIsModified(false) : setIsCompared(false);
                dispatch(showSnackbar(toUpdate === "all" ? "Hébergement mis à jour avec succès" : "Prix de l'hébergement mis à jour avec succès", "success"));
                window.location.reload();
              })
              .catch((err) => setError(err));
          } else if (response.status === 401) {
            dispatch(sessionEnd());
          } else {
            response.json().then((result) => setError(result.message));
          }
        })
        .catch((err) => setError(err));
    }
  }

  const classicTypes = ["simple", "double"];
  const metadataFields =[
    {"label": "Nom de l'hébergement :", "data": hosting.name},
    {"label": "Description de l'hébergement :", "data": hosting.description ? hosting.description : "Aucune description de l'hébergement"},
    {"label": "Type de l'hébergement :", "data": hosting.type === "classic" ? "Classique" : "Personnalisé"},
    {"label": "Client :", "data": hosting.client.name},
    {"label": "Environnement(s) :", "data": hosting.environment},
    {"label": "Marge appliquée :", "data": hosting.margin+" %"},
    {"label": "Création :", "data": "par "+hosting.author+" le "+new Date(hosting.createdAt).toLocaleString()},
  ]

  const buttons = [
    {"label" : "Métadonnées", "color": "", "onClick" : () => {setIsModified(true)}, "startIcon": <EditTwoToneIcon />, "condition" : true},
    {"label" : isCompared ? "Modifier les prix" : "Comparer les prix hébergeur", "color": isCompared ? "#a1ff90" : "", "onClick" : () => {isCompared ? updateHosting("prices") : setIsCompared(true)}, "startIcon": isCompared && (<EditTwoToneIcon />), "condition": true},
    {"label" : "Annuler la comparaison", "color": "#ffa490", "onClick" :() => { setIsCompared(false) }, "startIcon": "", 'condition': isCompared},
  ]

  return (
    <Box className="container-ProjectDetails" sx={{ flexDirection: !isModified ? "row" : "row-reverse" }}>
      <Grid container spacing={3} sx={{ "position": "relative" }}>
        {!isModified && (
          <>
            <KeyboardArrowDown sx={arrowStyle} onClick={() => {
              setIsSwitched(!isSwitched);
              setIsRotated(!isRotated);
            }} />
            {hosting.type === "classic" ? (
              classicTypes.map((type) => {
                  return(
                    <Grid item xs={isSwitched ? 6 : 7.5} md={isSwitched ? 4.5 : 6}>
                      <Typography variant="h5" sx={{textAlign: "center"}}>FORMULE {type.toUpperCase()}</Typography>
                      <Typography variant="h6" sx={{textAlign: "center"}}>Nombre d'heures d'utilisation par mois : 720 heures</Typography>
                      <Grid overflow={"scroll"} height={"700px"}>
                        <HostingCostSummary 
                          hosting={hosting}
                          type={type}
                          prices={prices}
                          isCompared={isCompared}
                        />
                      </Grid>
                    </Grid>
                  )
                })
            ) : (
              <Grid item xs={isSwitched ? 12 : 15} md={isSwitched ? 9 : 12}>
                <Typography variant="h5" sx={{textAlign: "center"}}>FORMULE PERSONNALISEE</Typography>
                <Typography variant="h6" sx={{textAlign: "center"}}>Nombre d'heures d'utilisation par mois : 720 heures</Typography>
                <Grid overflow={"scroll"} height={"700px"}>
                  <HostingCostSummary 
                    hosting={hosting}
                    type="personnalisée"
                    prices={prices}
                    isCompared={isCompared}
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}
        {isSwitched &&
          <Grid item xs={12} md={isModified ? 12 : 3} justifyContent="center">
            <Box sx={{marginTop: "15px", marginRight: "20px", mx: "auto", width: isModified ? "fit-content" : "100%",}}>
              <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "flex-start", width: "100%",}}>
                {isModified ? (
                  <Box sx={{ height: "fit-content", width: "800px", border: "1px solid rgba(0,0,0,0.2)", borderRadius: "10px", padding: "30px",backgroundColor: "white",}}>
                    <Box sx={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2,}}>
                      <Typography variant="h6">Métadonnées de l'hébergement</Typography>
                      <IconButton onClick={() => {setOpenConfirmClose(true);}}><CloseIcon /></IconButton>
                    </Box>
                    <AddUpdateHosting 
                        hosting={hosting}
                        handleSubmit={updateHosting}
                        edit={true}
                        isSwitched={hosting.backup.retentionTime !== 0 ? true : false}
                        typeChecked={hosting.type === "classic" ? false : true}
                        setTypeChecked={setTypeChecked}
                      />
                  </Box>
                ):(
                  <Box className="projectdetails__card">
                    {
                      metadataFields.map((field)=>{
                        return(
                          <>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Typography sx={{ color: colorDarkOrange }} variant="subtitle2">{field.label}</Typography>
                            </Box>
                            <Typography mb={2}>{field.data}</Typography>
                          </>
                        )
                      })
                    }
                    {isModified ? null : (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {buttons.map((button) => {
                            return(
                              button.condition && (
                              <Button startIcon={button.startIcon} variant="contained" sx={{ marginTop: 3, backgroundColor: button.color}} onClick={button.onClick}>
                                  {button.label}
                                </Button>
                              )
                            )
                          })}
                      </Box>
                    )}
                  </Box>)}
              </Box>
            </Box>
          </Grid>}
      </Grid>
      {openConfirmClose && (
        <AlerteDialog
          open={openConfirmClose}
          handleClose={()=>setOpenConfirmClose(false)}
          deleteProject={()=>{
            setIsModified(false);
            setOpenConfirmClose(false);
          }}
          title={"Souhaitez vous vraiment quitter sans enregistrer ?"}
          contentText={
            "Attention vous êtes sur le point de quitter la modification sans enregister. Toute donnée non enregistrée sera perdue !"
          }
        />
      )}
    </Box>
  );
};

export default HostingDetails;
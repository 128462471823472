// React
import { useState, useEffect } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd, showSnackbar } from "../../redux/actions/index";
// Tools
import { useNavigate } from "react-router-dom";

// MaterialUI
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

// Components
import AddUpdateProject from "./AddUpdateProject";
import { colorDarkOrange } from "../../config/color";
import ProjectCostsSummary from "./ProjectCostsSummary";
import MatrixDialogEdit from "../ListMatrix/MatrixDialogEdit";

// Interface
import { MatriceWrapper } from "../../interface/matrice";
import { IProject } from "../../interface/project";

// Fetch
import { getOne } from "../../fetchs/get";
import { fetchPostProtect } from "../../fetchs/post";

// Icons
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

// // Websocket
// import { io } from "socket.io-client";
// import { useIdle } from "@uidotdev/usehooks";
// import { wsURL } from "../../config/config";

type ProjectType = {
  isLock: boolean,
  projectId: string,
  socketId: string,
  username: string
}

const ProjectDetails = ({
  project,
  isModified,
  setIsModified,
  isModifiedMatrice,
  setIsModifiedMatrice,
  updateProject,
  setLotId,
  lotId,
  setOpenModal,
}: {
  project: IProject;
  isModified: boolean;
  setIsModified: Function;
  isModifiedMatrice: boolean;
  setIsModifiedMatrice: Function;
  updateProject: Function;
  setLotId: any;
  lotId: string;
  setOpenModal: Function;
}) => {
  const [matrix, setMatrix] = useState<any>([]);
  let navigate = useNavigate();
  let dispatch = useDispatch();

  let token = useSelector((state: RootState) => {
    return state.user.token;
  });

  // console.log(wsURL)


  useEffect(() => {
    if (token && project && isModifiedMatrice) {
      getOne(token,
        "/matrice/id/",
        project.defaultMatrice,
        setMatrix, () => {
          dispatch(sessionEnd());
        }, (err: any) => {
          navigate(`/projets/`);
          dispatch(showSnackbar(`Une erreur s'est produite avec la matrice du projet  ${project.name} : ` + err.error, "error"));
          console.error(`Une erreur s'est produite avec la matrice du projet ${project.name} : ` + err.error);
        });
    }
  }, [isModifiedMatrice, project]);

  // const idle = useIdle(1800000);
  // const [openIdleModal, setOpenIdleModal] = useState(false);
  // const [userActive, setUserActive] = useState(false);
  // const username = useSelector((state: RootState) => {
  //   return state.user.username;
  // });

  // useEffect(() => {
  //   let timeout: NodeJS.Timeout;
  //   if (idle && !userActive) {
  //     setOpenIdleModal(true);
  //     timeout = setTimeout(() => {
  //       setOpenIdleModal(false);
  //       navigate("/projets");
  //     }, 10000);
  //   }
  //   return () => clearTimeout(timeout);
  // }, [idle, userActive, navigate]);

  // const resetIdle = () => {
  //   setUserActive(false);
  // };

  // useEffect(() => {
  //   console.log("ici ici ici", wsURL)


  //   const socket = io(wsURL, { transports: ['websocket', 'polling'] });



  //   const ProjectsLocked = (projectsLockedList: ProjectType[]) => {
  //     const lockedProject = projectsLockedList.find(projet => projet.projectId === project._id);

  //     if (lockedProject && lockedProject.isLock && lockedProject.username !== username) {
  //       navigate("/projets");
  //     } else if ((!lockedProject || !lockedProject.isLock) && window.location.pathname.includes(`/projet/${project._id}`)) {
  //       socket.emit("projectLock", project._id, username);
  //     }
  //   };

  //   socket.emit("areProjectsLocked2");
  //   socket.on("areProjectsLocked2", ProjectsLocked);

  //   return () => {
  //     const currentProjectId = window.location.pathname.split('/')[2];
  //     if (currentProjectId !== project._id) {
  //       socket.emit("projectUnlock", project._id);
  //     }
  //     socket.off();

  //   };
  // }, [project._id, username, navigate]);


  const pushModifiedMatrice = (updatedMatriceData: MatriceWrapper) => {
    const body: MatriceWrapper = {
      ...updatedMatriceData,
      name: updatedMatriceData.name.trim(),
      desc: updatedMatriceData.desc ? updatedMatriceData.desc.trim() : "",
    };
    setMatrix(body);
    if (token) {
      fetchPostProtect(token, "/matrice/update", JSON.stringify(body)).then(
        (res) => {
          if (res.status === 200) {
            dispatch(showSnackbar("Matrice mise à jour", "success"));
            setIsModifiedMatrice(false);
          } else if (res.status === 401) {
            dispatch(sessionEnd());
          } else {
            res.json().then((err) => {
              navigate(`/projets/`);
              dispatch(
                showSnackbar(
                  "Erreur lors de la mise a jour de la Matrice : " + err.error,
                  "error"
                )
              );
              console.error(`Erreur lors de la mise a jour de la Matrice : ` + err.error);

            });
          }
        }
      );
    }
  };
  const [isSwitched, setIsSwitched] = useState<boolean>(true);
  const [isRotated, setIsRotated] = useState(false);

  const handleClick = () => { setIsRotated(!isRotated); };

  const arrowStyle = {
    float: 'right',
    position: 'absolute',
    top: "40px",
    right: "30px",
    transition: 'transform 0.5s ease',
    transform: isRotated ? 'rotate(90deg)' : 'rotate(270deg)',
    color: 'darkOrange',
    width: '40px',
    height: '40px'
  };

  return (

    <div
      className="container-ProjectDetails"
      style={{ flexDirection: !isModified ? "row" : "row-reverse" }}
    >
      <Grid container spacing={3} sx={{ "position": "relative" }}>
        {!isModified && (
          <>
            <KeyboardArrowDown sx={arrowStyle} onClick={() => {
              setIsSwitched(!isSwitched);
              handleClick();
            }} />

            <Grid item xs={isSwitched ? 12 : 15} md={isSwitched ? 9 : 12}>
              <ProjectCostsSummary
                project={project}
                setLotId={setLotId}
                lotId={lotId}
                setOpenModal={setOpenModal}
              ></ProjectCostsSummary>
            </Grid>
          </>
        )}
        {isSwitched &&
          <Grid item xs={12} md={isModified ? 12 : 3} justifyContent="center">
            <Box
              sx={{
                marginTop: "15px",
                marginRight: "20px",
                mx: "auto",
                width: isModified ? "fit-content" : "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                {isModified ? (
                  <Box
                    sx={{
                      height: "fit-content",
                      width: "fit-content",
                      border: "1px solid rgba(0,0,0,0.2)",
                      borderRadius: "10px",
                      padding: "30px",
                      backgroundColor: "white",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Typography variant="h6">Métadonnées du projet</Typography>
                      <IconButton
                        onClick={() => {
                          setIsModified(false);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <AddUpdateProject
                      project={project}
                      handleSubmit={updateProject}
                      edit={true}
                    />
                  </Box>
                ) : isModifiedMatrice ? (
                  <Box>
                    <IconButton
                      onClick={() => {
                        setIsModifiedMatrice(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    {matrix?.data && (
                      <MatrixDialogEdit
                        matrice={matrix}
                        setOpenModal={setIsModifiedMatrice}
                        pushModifiedMatrice={pushModifiedMatrice}
                        isMaster={false}
                        project={project}
                      />
                    )}
                  </Box>
                ) : (
                  <div className="projectdetails__card">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography sx={{ color: colorDarkOrange }} variant="subtitle2">
                        Nom du projet:
                      </Typography>
                    </div>
                    <Typography mb={2}>{project.name}</Typography>
                    {project.description !== "" && (
                      <>
                        <Typography sx={{ color: colorDarkOrange }} variant="subtitle2">
                          Description du projet:
                        </Typography>
                      </>
                    )}
                    <Typography mb={2} variant="subtitle1">
                      {project.description}
                    </Typography>
                    <Typography
                      sx={{ color: colorDarkOrange }}
                      variant="subtitle2"
                    >
                      Coordonnées du client:
                    </Typography>
                    <div>
                      <Typography component="p" variant="h5">
                        {project.client.name}{" "}
                      </Typography>
                      <Typography component="p" variant="h5">
                        {project.client.name_alias}
                      </Typography>
                      <Typography component="p" variant="h5">
                        {project.client.phone}{" "}
                      </Typography>
                      <Typography component="p" variant="h5">
                        {project.client.address}{" "}
                      </Typography>
                      <Typography component="p" variant="h5" mb={2}>
                        {project.client.zip} {project.client.town}
                      </Typography>
                    </div>
                    <Typography
                      sx={{ color: colorDarkOrange }}
                      variant="subtitle2"
                    >
                      Créé par :
                    </Typography>
                    <Typography mb={2} variant="subtitle1">
                      {project.author}
                    </Typography>
                    <Typography
                      sx={{ color: colorDarkOrange }}
                      variant="subtitle2"
                    >
                      Le :
                    </Typography>
                    <Typography mb={2} variant="subtitle1">
                      {new Date(project.createdAt).toLocaleString()}
                    </Typography>
                    <Typography sx={{ color: colorDarkOrange }} variant="subtitle2">
                      Dernièrement modifié par:
                    </Typography>
                    <Typography mb={2} variant="subtitle1">
                      {project.lastUpdatedBy}
                    </Typography>
                    <Typography sx={{ color: colorDarkOrange }} variant="subtitle2">
                      Dernièrement modifié le:
                    </Typography>
                    <Typography mb={2} variant="subtitle1">
                      {new Date(project.lastUpdatedDate).toLocaleString()}
                    </Typography>
                    {isModified ? null : (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Button
                          startIcon={<EditTwoToneIcon />}
                          variant="contained"
                          sx={{ marginTop: 5, marginBottom: 2 }}
                          onClick={() => {
                            setIsModified(true);
                          }}
                        >
                          Métadonnées
                        </Button>
                        <Button
                          startIcon={<EditTwoToneIcon />}
                          variant="contained"
                          onClick={() => {
                            setIsModifiedMatrice(true);
                          }}
                        >
                          Template Matrice
                        </Button>
                      </Box>
                    )}
                  </div>
                )}
              </Box>
            </Box>
          </Grid>
        }

      </Grid>

    </div>
  );
};

export default ProjectDetails;
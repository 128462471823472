// MaterialUI
import { Box, TextField, Button, Typography, styled } from "@mui/material";
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import Backspace from "@mui/icons-material/Backspace";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

//Yup
import * as yup from "yup";

// Formik
import { useFormik } from "formik";
import React from "react";
import { PriceData } from "../../interface/hosting";
import { useNavigate } from "react-router";


interface IFormValues {
  c34: GLfloat;
  c38: GLfloat;
  c316: GLfloat;
  nfs: GLfloat;
  floatingIP: GLfloat;
  router: GLfloat;
  backup: GLfloat;
}

const rulesValidationPrice = yup.number()
                            .positive("Le nombre doit être positif.")
                            .min(1e-9, "Le nombre doit être supérieur à zéro.")
                            .required("Ce champ est requis.");

const validationSchema = yup.object({
  c34: rulesValidationPrice,
  c38: rulesValidationPrice,
  c316: rulesValidationPrice,
  floatingIP: rulesValidationPrice,
  nfs: rulesValidationPrice,
  router: rulesValidationPrice,
  backup: rulesValidationPrice,
});

/** Ce composant permet aussi bien d'ajouter des projets que de modifier les projets voir page AddProject + page Project + composant ProjectDetails
 *
 * @param project optionnel le projet à modifier si besoin
 * @param handleSubmit le fetch a envoyé sur le back
 * @returns un composant de modification ou ajout projet
 */
const UpdateOvh = ({
  prices,
  handleSubmit,
  edit,
}: {
  prices: PriceData,
  handleSubmit: Function;
  edit: Boolean;
}) => {
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      c34: prices?.c34 ?? 0,
      c38: prices?.c38 ?? 0,
      c316: prices?.c316 ?? 0,
      nfs: prices?.nfs ?? 0,
      floatingIP: prices?.floatingIP ?? 0,
      router: prices?.router ?? 0,
      backup: prices?.backup ?? 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values: IFormValues) => {
      const formattedValues = {
        ...values,
        c34: Number(values.c34),
        c38: Number(values.c38),
        c316: Number(values.c316),
        nfs: Number(values.nfs),
        floatingIP: Number(values.floatingIP),
        router: Number(values.router),
        backup: Number(values.backup),
      };
      handleSubmit(formattedValues);
    },
  });

  const divStyle = {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 15
  }

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const priceFields = [
    { id: "c34", label: "Serveur c3-4" },
    { id: "c38", label: "Serveur c3-8" },
    { id: "c316", label: "Serveur c3-16" },
    { id: "nfs", label: "Serveur NFS" },
    { id: "floatingIP", label: "IP Flottantes" },
    { id: "router", label: "Router" },
    { id: "backup", label: "Backup" },
  ]

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1, maxWidth: 500, width: "100%"}}>
      {priceFields.map((field) => {
        return(
          <div style={divStyle}>
            <p style={{width: "150px"}}>
              {field.label}
            </p>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Prix unitaire par heure {field.id === "nfs" || field.id === "backup" ? "ET par Giga" : ""}</Typography>
                </React.Fragment>
              }
            >
              <InfoIcon sx={{ opacity: 0.25, transition: "opacity 0.3s", "&:hover": { opacity: 1 }}}></InfoIcon>
            </HtmlTooltip>
            <TextField
              type="GLfloat"
              onChange={formik.handleChange}
              value={formik.values[field.id as keyof typeof formik.values]}
              id={field.id}
              name={field.id}
            />
          </div>
        )
      })}
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} startIcon={<SendTwoToneIcon />}>Enregistrer</Button>
      <Button type="button" onClick={()=>{ navigate('/hebergement')}}fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} startIcon={<Backspace />}>Annuler</Button>
    </Box>
  );
};

export default UpdateOvh;

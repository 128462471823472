import * as React from "react";

// MaterialUI
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";

// Config
import { colorDarkWhite } from "../config/color";

// Component
import DrawerHeader from "./DrawerHeader";
import PermanentDrawer from "./Drawer";

// Icons
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemIcon from "@mui/material/ListItemIcon";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import GridOnIcon from "@mui/icons-material/GridOn";
import HistoryIcon from '@mui/icons-material/History';
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import UpdateIcon from "@mui/icons-material/Update";

//Logo
import logo from "../img/logo/Kairos-removebg-preview.webp";

// React router dom
import { Outlet, useNavigate } from "react-router-dom";

// Redux
import { sessionEnd } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/index";

// Tools
import useWindowDimensions from "../tools/useWindowDimensions";

// Données du menu de navigation
const menuData = [
  {
    groupName: "Projets",
    items: [
      {
        icon: <ListOutlinedIcon />,
        text: "Liste des projets",
        page: "/projets",
      },
      {
        icon: <PlaylistAddOutlinedIcon />,
        text: "Ajouter un projet",
        page: "/projets/ajout",
      },
    ],
  },
  {
    groupName: "TMA",
    items: [
      {
        icon: <ListOutlinedIcon />,
        text: "Liste des TMA",
        page: "/TMA",
      },
      {
        icon: <PlaylistAddOutlinedIcon />,
        text: "Ajouter une TMA",
        page: "/TMA/ajout",
      },
    ],
  },
  {
    groupName: "Hébergements",
    items: [
      {
        icon: <ListOutlinedIcon />,
        text: "Liste des hébergements",
        page: "/hebergement",
      },
      {
        icon: <PlaylistAddOutlinedIcon />,
        text: "Ajouter un hébergement",
        page: "/hebergement/ajout",
      },
      {
        icon: <UpdateIcon />,
        text: "Mettre à jour des prix",
        page: "/hebergement/ovh-prices",
      },
    ],
  },
  {
    groupName: "Matrices",
    items: [
      {
        icon: <GridOnIcon />,
        text: "Liste des matrices",
        page: "/matrices",
      },
      {
        icon: <LibraryAddOutlinedIcon />,
        text: "Ajouter une matrice",
        page: "/matrices/ajout",
      },
    ],
  },
  {
    groupName: "Historique",
    items: [
      {
        icon: <HistoryIcon />,
        text: "Historique",
        page: "/history",
      },
    ],
  },
];

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export default function Layout() {
  const dispatch = useDispatch();

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [locked, setLocked] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  let navigate = useNavigate();

  const { width } = useWindowDimensions();
  const user = useSelector((state: RootState) => state.user.username);

  /*
  En utilisant le hook useWindowDimensions, on peut récupérer la hauteur et la largeur de la fenêtre
  On déclare un bool permettant de definir si la fenêtre est en mode mobile 
  */
  let mobile: boolean = false;
  if (width) {
    mobile = width > 900 ? false : true;
  }

  const drawerWidth = 280;

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const handleDrawerOpen = () => {
    if (!locked) {
      setOpen(true);
      setIsVisible(true);
    }
  };

  const handleDrawerClose = () => {
    if (!locked) {
      setOpen(false);
      setIsVisible(false);
    }
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const toggleLock = () => {
    setLocked(!locked);
  };

  const drawerContext = (
    <React.Fragment>
      {mobile ? (
        <Toolbar />
      ) : (
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon sx={{ color: colorDarkWhite }} />}
          </IconButton>
        </DrawerHeader>
      )}
      <List>
        {menuData.map((group) => (
          <List
            subheader={
              <ListSubheader
                sx={{
                  backgroundColor: "transparent",
                  color: colorDarkWhite,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  opacity: 0.5,
                }}
              >
                {group["groupName"]}
                {group.groupName === "Projets" && (
                  <IconButton onClick={toggleLock} sx={{ color: colorDarkWhite }}>
                    {locked ? <LockIcon /> : <LockOpenIcon />}
                  </IconButton>
                )}
              </ListSubheader>
            }
            key={group["groupName"]}
          >
            {group["items"].map((menu) => (
              <ListItem
                button
                key={menu["text"]}
                onClick={() => {
                  navigate(menu["page"]);
                }}
              >
                <ListItemIcon sx={{ color: colorDarkWhite }}>{menu["icon"]}</ListItemIcon>
                <ListItemText primary={menu["text"]} sx={{ color: colorDarkWhite }} />
              </ListItem>
            ))}
          </List>
        ))}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <Typography variant="body2" color="white" align="center" sx={{ marginBottom: "14px", opacity: 0.5, fontSize: 12 }}>
        V.{window._env_.KAIROS_VERSION}
      </Typography>
    </React.Fragment>
  );

  const appBarContext = (
    <Toolbar>
      <div className="flex-container-navbar">
        <div className="flex-container-left">
          {mobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: { xs: "10px", md: "36px" },
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box
            component="img"
            sx={{
              height: 48,
              width: 37,
              cursor: "pointer",
            }}
            alt="logo Kairos"
            src={logo}
            className="logo-kairos"
            onClick={() => navigate("/")}
          />
        </div>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle2" noWrap component="div" sx={{ flexGrow: 1 }}>
            {user}
          </Typography>
          <IconButton
            edge="end"
            sx={{
              color: "red",
              marginLeft: { xs: "10px", md: "20px" },
            }}
            onClick={() => dispatch(sessionEnd())}
            aria-label="fin de session"
          >
            <PowerSettingsNewIcon />
          </IconButton>
        </Box>
      </div>
    </Toolbar>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {mobile ? (
        <>
          <MuiAppBar position="fixed" color="secondary">
            {appBarContext}
          </MuiAppBar>
          <Drawer
            sx={{
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "#434241",
              },
            }}
            variant="temporary"
            open={open}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawerContext}
          </Drawer>
        </>
      ) : (
        <>
          <AppBar position="fixed" color="secondary">
            {appBarContext}
          </AppBar>
          <PermanentDrawer
            variant="permanent"
            open={open}
            onMouseEnter={() => !locked && setOpen(true)}
            onMouseLeave={() => !locked && setOpen(false)}
            sx={{
              "& .MuiDrawer-paper": {
                backgroundColor: "#434241",
              },
            }}
          >
            {drawerContext}
          </PermanentDrawer>
        </>
      )}
      {/*//! Extrenaliser ce composant box et son style ??? */}
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        {/* Outlet permet de remplacer children pour une route */}
        <Outlet />
      </Box>
    </Box>
  );
}

import React from "react";
import "./sass/App.scss";

// Layouts
import Layout from "./layout/Layout";

// Pages
import Login from "./pages/Login/Login";
import Home from "./pages/Home";
import ListProjects from "./pages/Projects/ListProjects";
import AddProject from "./pages/Projects/AddProject";
import NotFound from "./pages/NotFound";
import Project from "./pages/Projects/Project";
import MatrixHome from "./pages/Matrix/Matrix";
import AddMatrix from "./pages/Matrix/AddMatrix";
import AzureAuth from "./pages/AzureAuth";
import History from "./pages/History/History";
import AddTMA from "./pages/TMA/AddTMA";
import TMA from "./pages/TMA/TMA";
import ListHostings from "./pages/Hebergements/ListHostings";
import AddHosting from "./pages/Hebergements/AddHosting";
import UpdateOvhPrices from "./pages/Hebergements/UpdateOvhPrices";
import Hosting from "./pages/Hebergements/Hosting";

// Composant Auth
import RequireAuth from "./components/Auth/RequireAuth";

// Composant Snackbar
import SnackbarWrapper from "./components/Snackbar/SnackbarWrapper";

// React router dom
import { BrowserRouter, Routes, Route } from "react-router-dom";



function App() {
  return (
    <BrowserRouter>
      <SnackbarWrapper/>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/azureAuth" element={<AzureAuth />} />
        <Route
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/projets" element={<ListProjects />} />
          <Route path="/projets/ajout" element={<AddProject />} />
          <Route path="/projet/:projectId" element={<Project />} />
          <Route path="/TMA" element={<TMA />} />
          <Route path="TMA/ajout" element={<AddTMA />} />
          <Route path="/hebergement" element={<ListHostings />} />
          <Route path="/hebergement/:hostingId" element={<Hosting />} />
          <Route path="/hebergement/ajout" element={<AddHosting />} />
          <Route path="/hebergement/ovh-prices" element={<UpdateOvhPrices />} />
          <Route path="/matrices" element={<MatrixHome />} />
          <Route path="/matrices/ajout" element={<AddMatrix />} />
          <Route path="/history" element={<History />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import { useEffect, useState } from "react";
import { IHosting, PriceData } from "../../interface/hosting";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

export default function HostingCostSummary({
    hosting,
    type,
    prices,
    isCompared
} : {
    hosting: IHosting,
    type: string,
    prices: PriceData[],
    isCompared: boolean,
}) {
    const calculateTotalHT = (prices : PriceData): number => {
        if (type !== "personnalisée") {
            return (
                720 * (
                    (type === "double" ? prices.c34 * 2 : prices.c34) +
                    (type === "double" ? prices.c38 * 2 : prices.c38) +
                    (type === "double" ? prices.floatingIP * 4 : prices.floatingIP * 2) +
                    prices.router +
                    (hosting.backup.storage !== 0 ? prices.backup * hosting.backup.storage : 0)
                ) * (1 + hosting.margin / 100)
            );
        } else {
            const customServersTotal = hosting.custom.servers.reduce((total, server) => {
                if(server.type === "nfs"){
                    return total + prices.nfs*server.storage;
                }
                else {
                    return total + (prices[server.type as keyof typeof prices] as number);
                }
            }, 0)
    
            return (
                720 * (
                    customServersTotal +
                    prices.floatingIP * hosting.custom.nbIP +
                    prices.router +
                    (hosting.backup.storage !== 0 ? prices.backup * hosting.backup.storage : 0)
                ) * (1 + hosting.margin / 100)
            );
        }
    };

    const currentTotal = calculateTotalHT({ "_id" : "", ...hosting.prices });

    const [totalHT, setTotalHT] = useState<number>(0);

    useEffect(() => {
        const priceSource = isCompared ? { ...prices[0] } : { "_id" : "", ...hosting.prices }; 
        setTotalHT(calculateTotalHT(priceSource));
    }, [isCompared, hosting]);

    const comparedColor = {
        c34 : isCompared && (prices[0].c34 > hosting.prices.c34 ? "red" : (prices[0].c34 < hosting.prices.c34 ? "green" : "black")),
        c38 : isCompared && (prices[0].c38 > hosting.prices.c38 ? "red" : (prices[0].c38 < hosting.prices.c38 ? "green" : "black")),
        c316 : isCompared && (prices[0].c316 > hosting.prices.c316 ? "red" : (prices[0].c316 < hosting.prices.c316 ? "green" : "black")),
        nfs : isCompared && (prices[0].nfs > hosting.prices.nfs ? "red" : (prices[0].nfs < hosting.prices.nfs ? "green" : "black")),
        floatingIP : isCompared && (prices[0].floatingIP > hosting.prices.floatingIP ? "red" : (prices[0].floatingIP < hosting.prices.floatingIP ? "green" : "black")),
        router : isCompared && (prices[0].router > hosting.prices.router ? "red" : (prices[0].router < hosting.prices.router ? "green" : "black")),
        backup : isCompared && (prices[0].backup > hosting.prices.backup ? "red" : (prices[0].backup < hosting.prices.backup ? "green" : "black")),
        total : isCompared && (totalHT > currentTotal ? "red" : (totalHT < currentTotal ? "green" : "black")),
    }

    const serverDatas = type !== "personnalisée" ? 
        (type === "simple" ? [
            ["SRV-LOADBALANCER-1", "c3-4", (isCompared ? prices[0].c34 : hosting.prices.c34)+" €"],
            ["SRV-CLT-1", "c3-8", (isCompared ? prices[0].c38 : hosting.prices.c38)+" €"],
        ] : [
            ["SRV-LOADBALANCER-1", "c3-4", (isCompared ? prices[0].c34 : hosting.prices.c34)+" €"],
            ["SRV-LOADBALANCER-2", "c3-4", (isCompared ? prices[0].c34 : hosting.prices.c34)+" €"],
            ["SRV-CLT-1", "c3-8", (isCompared ? prices[0].c38 : hosting.prices.c38)+" €"],
            ["SRV-CLT-2", "c3-8", (isCompared ? prices[0].c38 : hosting.prices.c38)+" €"],
        ]) : hosting.custom.servers.map((server) => {
            const formattedType = server.type !== "nfs" ? server.type.replace("c3", "c3-") : server.type.toUpperCase() + " - " +server.storage+" Go"
            return(
                [server.instance, formattedType, (isCompared ? prices[0][server.type] : hosting.prices[server.type])+" €"]
            )
        })
    
    const colorServerDatas = type !== "personnalisée" ? 
        (type === "simple" ? [comparedColor.c34, comparedColor.c38] : [comparedColor.c34, comparedColor.c34, comparedColor.c38, comparedColor.c38]) 
        : hosting.custom.servers.map((server) => comparedColor[server.type])

    const tableSections = [
        {
            "header" : "SERVEURS", 
            "columns" : ["Nom d'instance", "Type de serveur", "Prix à l'heure"],
            "datas" : serverDatas,
            "colors" : colorServerDatas,
        },
        {
            "header" : "IP FLOTTANTES", 
            "columns" : ["Nombre d'IP", "", "Prix à l'heure"], 
            "datas" : [[type !== "personnalisée" ? (type === "simple" ? 2 : 4) : hosting.custom.nbIP, "", (isCompared ? prices[0].floatingIP : hosting.prices.floatingIP) + " €"]],
            "colors" : [comparedColor.floatingIP]
        },
        {
            "header" : "ROUTEUR", 
            "columns" : ["Prix à l'heure : " + (isCompared ? prices[0].router : hosting.prices.router) + " €"],
            "colors" : [comparedColor.router]
        },
        {
            "header" : "BACKUP", 
            "columns" : hosting.backup.storage !== 0 ? ["Temps de retention", "Stockage", "Prix à l'heure par giga"] : ["Aucun bakcup demandé"], 
            "datas": hosting.backup.storage !== 0 ? [[hosting.backup.retentionTime+" jours", hosting.backup.storage+" Go", (isCompared ? prices[0].backup : hosting.prices.backup) + " €"]] : undefined,
            "colors" : [comparedColor.backup]
        },
        {
            "header" : "TOTAL HT PAR MOIS",
            "columns" : [totalHT.toFixed(2)+" €"],
            "colors" : [comparedColor.total]
        }
    ];
    
    return (
        <TableContainer sx={{ width: "100%"}}>
            <Table sx={{ width: "100%", overflow: "scroll" }} aria-label="simple table">
                {
                    tableSections.map((section) => {
                        return(
                            <>
                                <TableHead sx={{ backgroundColor: "#000", height: "25px"}}>
                                    <TableRow>
                                        <TableCell colSpan={3} sx={{ color : "white", textAlign: "center"}}>{section.header}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                    {
                                        section.columns.map((column) => {
                                            return(
                                                <TableCell colSpan={(3/section.columns.length)} sx={{textAlign: "center", fontWeight: section.columns.length>1 ? "bold" : "", color: !section.datas ? section.colors[0] || "" : ""}}>{column}</TableCell>
                                            )
                                        })
                                    }
                                    </TableRow>
                                    {
                                        section.datas && section.datas.map((data, index) => {
                                            return(
                                                <TableRow>
                                                    <TableCell colSpan={(3/section.columns.length)} sx={{textAlign: "center"}}>{Array.isArray(data) ? data[0] : ""}</TableCell>
                                                    <TableCell colSpan={(3/section.columns.length)} sx={{textAlign: "center"}}>{Array.isArray(data) ? data[1] : ""}</TableCell>
                                                    <TableCell colSpan={(3/section.columns.length)} sx={{textAlign: "center", color: section.colors[index] || ""}}>{Array.isArray(data) ? data[2] : ""}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </>
                        )
                    })
                }
            </Table>
        </TableContainer>
    );
}
import { Autocomplete, Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { IHosting } from "../../interface/hosting";
import { MouseEventHandler } from "react";

const CustomAddUpdateFields = ({
  handleAddServer,
  handleRemoveServer,
  customServer,
  formik,
  hosting,
  typeChecked
} : {
  handleAddServer : MouseEventHandler<HTMLButtonElement>,
  handleRemoveServer : MouseEventHandler<HTMLButtonElement>,
  customServer : number,
  formik : any,
  hosting : IHosting | undefined,
  typeChecked: boolean
}) => {
    return (
        <>
          <Box sx={{width: "100%", display: "flex", justifyContent: "space-between"}}>
            <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleAddServer}>+ Ajouter un serveur</Button>
            <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleRemoveServer}>- Retirer un serveur</Button>
          </Box>
          <Box sx={{width: "100%",display: "flex",flexDirection: "column",justifyContent: "space-between"}}>
            {Array.from({ length: customServer }, (_, i) => (
              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between"}}>
                <TextField
                  margin="normal"
                  required
                  type="text"
                  id={`instance-${i}`}
                  label="Nom de l'instance"
                  name={`instance-${i}`}
                  autoComplete={`instance-${i}`}
                  value={formik.values[`instance-${i}` as keyof typeof formik.values]}
                  onChange={formik.handleChange}
                />
                <Autocomplete
                  style={{width: "40%"}}
                  id={`server-${i}`}
                  options={["c34", "c38", "c316", "nfs"]}
                  getOptionLabel={(option: any) => option !== "nfs" ? option.replace("c3", "c3-") : option.toUpperCase()}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option !== "nfs" ? option.replace("c3", "c3-") : option.toUpperCase()}
                    </li>
                  )}
                  defaultValue={hosting ? (hosting.custom.servers[i] ? hosting.custom.servers[i].type : null) : null}
                  onChange={(event, value) => {
                    if (value) {
                      formik.setFieldValue(`server-${i}`, value);
                      if (value !== "nfs") {
                        if(value === "c34"){
                          formik.setFieldValue(`storage-${i}`, 50);
                        } else if(value === "c38"){
                          formik.setFieldValue(`storage-${i}`, 100);
                        } else if(value === "c316"){
                          formik.setFieldValue(`storage-${i}`, 200);
                        }  
                      }
                    } else {
                      formik.setFieldValue(`server-${i}`, "");
                      formik.setFieldValue(`storage-${i}`, ""); // Réinitialiser si aucun serveur n'est sélectionné
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={`server-${i}`}
                      margin="normal"
                      label={`Type de serveur`}
                      fullWidth
                      required
                    />
                  )}
                />
                <TextField
                  margin="normal"
                  required
                  type="number"
                  id={`storage-${i}`}
                  label="Nombre de Go"
                  name={`storage-${i}`}
                  autoComplete={`storage-${i}`}
                  defaultValue={0}
                  value={formik.values[`storage-${i}` as keyof typeof formik.values]}
                  onChange={formik.handleChange}
                  disabled={(formik.values[`server-${i}` as keyof typeof formik.values] !== "nfs")}
                  inputProps={{
                    min: "1",
                    step: "1",
                  }}
                />
              </Box>
            ))}
            <TextField
              margin="normal"
              required
              type="number"
              id="nbIP"
              label="Nombre d'IP flottantes"
              name="nbIP"
              autoComplete="nbIP"
              value={typeChecked ? formik.values.nbIP : 0}
              onChange={formik.handleChange}
              error={formik.touched.nbIP && Boolean(formik.errors.nbIP)}
              helperText={formik.touched.nbIP && formik.errors.nbIP}
              autoFocus
            />
          </Box>
        </>
    )
}

export default CustomAddUpdateFields;
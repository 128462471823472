//MUI
import { Alert, Card, Typography } from "@mui/material";
import { Box } from "@mui/system";

import NavTab from "../../layout/NavTab";
import { error } from "console";
import UpdateOvh from "../../components/Hostings/UpdateOvhPrices";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { getAll } from "../../fetchs/get";
import { useEffect, useState } from "react";
import { sessionEnd, showSnackbar } from "../../redux/actions";
import { fetchPutProtect } from "../../fetchs/put";
import { PriceData } from "../../interface/hosting";
import { useNavigate } from "react-router";

interface IFormValues {
  c34: GLfloat;
  c38: GLfloat;
  c316: GLfloat;
  nfs: GLfloat;
  floatingIP: GLfloat;
  router: GLfloat;
  backup: GLfloat;
}

const UpdateOvhPrices = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  
  const [error, setError] = useState<string>("");
  const [prices, setPrices] = useState<PriceData[]>([]);
  
  let token = useSelector((state: RootState) => {
    return state.user.token;
  });
  
  const getPrices = () => {
    if (token) {
      getAll(token, "/hosting/ovh", setPrices, () => {
        dispatch(sessionEnd());
      },
      setError);
    }
  }

  useEffect(() => {
    getPrices();
  }, []);

  const updatePrices = async (values: IFormValues) => {
    const body = JSON.stringify({
      _id: prices[0]._id,
      c34: values.c34,
      c38: values.c38,
      c316: values.c316,
      floatingIP: values.floatingIP,
      nfs: values.nfs,
      router: values.router,
      backup: values.backup
    });
    if (token) {
      fetchPutProtect(token, "/hosting/updateovh", body)
        .then((response) => {
          if (response.status === 200) {
            setError("");
            dispatch(showSnackbar("Prix hébergeur mis à jour avec succés", "success"));
            navigate('/hebergement')
          } else if (response.status === 401) {
            dispatch(sessionEnd());
          } else {
            response.json().then((result) => setError(result.message));
          }
        })
        .catch((err) => setError(err));
    }
  };


  return (
    <div className="addProject">
        <NavTab text="Mettre à jour les prix de l'hébergeur" showDialog={true} />
        <Card
          elevation={0}
          component="main"
          sx={{
            backgroundColor: "rgba(255,255,255,0.45)",
            m: 2,
            p: 2,
            borderRadius: "10px",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            overflowY: 'auto'
          }}
        >
          <Box sx={{ height: "fit-content", width: "fit-content",
              border: "1px solid rgba(0,0,0,0.2)",
              borderRadius: "10px",
              padding: "30px",
              backgroundColor: "white",
          }}>
          <Typography variant="subtitle2" component="div" align="center">
              Merci de modifier les champs pour mettre à jour les prix de l'hébergeur
          </Typography>
          {error ? <Alert severity="error">{error}</Alert> : null}
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "flex_start",
            }}
          >
            {prices.length > 0 && (
              <UpdateOvh prices={prices[0]} handleSubmit={updatePrices} edit={false} />
            )}
          </div>
          </Box>
        </Card>
      </div>
    );
  };
export default UpdateOvhPrices
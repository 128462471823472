import { Autocomplete, TextField } from "@mui/material";
import { IHosting } from "../../interface/hosting";

const CommonAddUpdateFields = ({
    formik,
    clientActeam,
    edit,
    hosting,
} : {
    formik : any,
    clientActeam : any,
    edit : boolean,
    hosting : IHosting | undefined,
}) => {
    return(
    <>
        <TextField
            margin="normal"
            required
            fullWidth
            type="text"
            id="name"
            label="Nom de l'hébergement"
            name="name"
            autoComplete="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            autoFocus
        />
        <TextField
            multiline
            minRows={2}
            fullWidth
            maxRows={6}
            type="textarea"
            autoComplete="description"
            label="Description de l'hébergement"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
            margin="normal"
            id="description"
            name="description"
        />
        <Autocomplete
            id="client"
            options={clientActeam}
            disabled={edit}
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            fullWidth
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option._id}>
                        {option.name}
                    </li>
                );
            }}
            defaultValue={hosting ? { _id: hosting.client._id, name: hosting.client.name } : null}
            onChange={(_event, value) => {
            if (value) {
                formik.setFieldValue("client", value._id);
            } else {
                formik.setFieldValue("client", "");
            }
            }}
            renderInput={(params) => (
            <TextField
                {...params}
                required
                name="client"
                margin="normal"
                label="Client"
                fullWidth
                error={formik.touched.client && Boolean(formik.errors.client)}
                helperText={formik.touched.client && formik.errors.client}
            />
            )}
        />
        <Autocomplete
            id="environment"
            options={["Prod", "Prod + Preprod"]}
            getOptionLabel={(option: any) => option}
            isOptionEqualToValue={(option, value) => option === value}
            fullWidth
            renderOption={(props, option) => {
            return (
                <li {...props} key={option}>
                {option}
                </li>
            );
            }}
            defaultValue={hosting ? hosting.environment : null}
            onChange={(event, value) => {
            if (value) {
                formik.setFieldValue("environment", value);
            } else {
                formik.setFieldValue("environment", "");
            }
            }}
            renderInput={(params) => (
            <TextField
                {...params}
                required
                name="envrionment"
                margin="normal"
                label="Environnement(s)"
                fullWidth
                error={formik.touched.environment && Boolean(formik.errors.environment)}
                helperText={formik.touched.environment && formik.errors.environment}
            />
            )}
        />
        <TextField
            margin="normal"
            required
            fullWidth
            type="number"
            id="margin"
            label="Marge appliquée (en %)"
            name="margin"
            autoComplete="margin"
            value={formik.values.margin}
            onChange={formik.handleChange}
            error={formik.touched.margin && Boolean(formik.errors.margin)}
            helperText={formik.touched.margin && formik.errors.margin}
            autoFocus
        />
    </>
    )
}

export default CommonAddUpdateFields;
import { TextField } from "@mui/material";
import { Box } from "@mui/system";

const BackupAddUpdateFields = ({
    formik,
    isSwitchChecked
} : {
    formik: any,
    isSwitchChecked : boolean
}) => {
    return (
        <Box sx={{width: "100%",display: "flex",justifyContent: "space-between"}}>
          <TextField
            style={{
              width: "40%"
            }}
            margin="normal"
            required
            type="number"
            id="retentionTime"
            label="Temps de retention (en jours)"
            name="retentionTime"
            autoComplete="retentionTime"
            value={isSwitchChecked ? formik.values.retentionTime : 0}
            onChange={formik.handleChange}
            error={formik.touched.retentionTime && Boolean(formik.errors.retentionTime)}
            helperText={formik.touched.retentionTime && formik.errors.retentionTime}
            autoFocus
          />
          <TextField
            style={{
              width: "40%"
            }}
            margin="normal"
            required
            type="number"
            id="storage"
            label="Stockage (en Go)"
            name="storage"
            autoComplete="storage"
            value={isSwitchChecked ? formik.values.storage : 0}
            onChange={formik.handleChange}
            error={formik.touched.storage && Boolean(formik.errors.storage)}
            helperText={formik.touched.storage && formik.errors.storage}
            autoFocus
          />
        </Box>
    )
}

export default BackupAddUpdateFields;
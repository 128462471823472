import { IHosting, PriceData } from "../interface/hosting";

interface IFormValues {
    name: string;
    description: string;
    client: string;
    environment: string;
    margin: number;
    retentionTime: number;
    storage: number;
    servers: [string];
    nbIP: number;
}


const addUpdateHostingData = async (values: IFormValues, prices: PriceData[], hosting?: IHosting, typeChecked?: boolean, author?: string) => {
    let flag = false;

    const servers = Object.keys(values)
      .filter(key => key.startsWith("instance-"))
      .map((instanceKey, index) => {
        const instance = values[instanceKey as keyof typeof values];
        const serverKey = `server-${index}`;
        const storageKey = `storage-${index}`;
        const server = values[serverKey as keyof typeof values];
        const storage = values[storageKey as keyof typeof values];
        if(server === "nfs" && (!storage || storage === 0)){
          flag = true;
        }
        
        return { instance, type: server, storage: storage};
    });
      
    const body = JSON.stringify({
      _id: hosting ? hosting._id : null,
      name: values.name,
      description: values.description,
      client: hosting ? hosting.client._id : values.client,
      author: hosting ? hosting.author : author,
      environment: values.environment,
      margin: values.margin,
      prices: prices[0],
      type: hosting ? hosting.type : (typeChecked ? "custom" : "classic"),
      backup: { retentionTime : values.retentionTime, storage : values.storage },
      custom: { servers: servers, nbIP: values.nbIP }
    });

    if(!flag) return body;
}

export default addUpdateHostingData



import { useState, useEffect, useCallback } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd } from "../../redux/actions/index";

// MaterialUI
import {Box, Button, Alert, Switch, Typography } from "@mui/material";

// Fetch
import { getAll } from "../../fetchs/get";

//Yup
import * as yup from "yup";

// Formik
import { useFormik } from "formik";
import { IHosting } from "../../interface/hosting";
import { IClient } from "../../interface/client";

// Color
import { colorOrange } from "../../config/color";

// Tools
import { updateDolibarrClients } from "../../tools/updateDolibarrClients";
import CommonAddUpdateFields from "./FieldsAddUpdateCommon";
import CustomAddUpdateFields from "./FieldsAddUpdateCustom";
import BackupAddUpdateFields from "./FieldsAddUpdateBackup";


interface IFormValues {
  name: string;
  description: string;
  client: string;
  environment: string;
  margin: number;
  retentionTime: number;
  storage: number;
  [key: `instance-${number}`]: string;
  [key: `server-${number}`]: string;
  [key: `storage-${number}`]: number;
  nbIP: number;
}

const validationSchema = yup.object({
  name: yup.string().trim().min(3, "Le nom du projet doit contenir au moins 3 caractères").required("Le nom de l'hébergement est obligatoire"),
  description: yup.string().trim().min(8, "La description doit contenir au moins 8 caractères"),
  client: yup.string().required("Le client est obligatoire"),
  environment: yup.string().required("L'environnement est obligatoire"),
  margin: yup.number().moreThan(-1, "La marge doit être supérieure à 0").required("La marge est obligatoire"),
  retentionTime: yup.number().moreThan(-1, "Le temps de rétention doit être supérieur à 0").required("Le temps de rentention est obligatoire"),
  storage: yup.number().moreThan(-1, "Le stockage du backup doit être supérieur à 0").required("Le stockage est obligatoire"),
  nbIP: yup.number().moreThan(-1, "Le nombre d'IP doit être supérieur à 0").required("Le nombre d'IP est obligatoire"),
});

/** Ce composant permet aussi bien d'ajouter des projets que de modifier les projets voir page Addhosting + page hosting + composant hostingDetails
 *
 * @param hosting optionnel le projet à modifier si besoin
 * @param handleSubmit le fetch a envoyé sur le back
 * @returns un composant de modification ou ajout projet
 */
const AddUpdateHosting = ({
  hosting,
  handleSubmit,
  isSwitched,
  typeChecked,
  setTypeChecked,
  edit,
}: {
  hosting?: IHosting;
  handleSubmit: Function;
  isSwitched: boolean;
  typeChecked: boolean;
  setTypeChecked: Function;
  edit: boolean;
}) => {
  const [clients, setClients] = useState<IClient[]>([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  let dispatch = useDispatch();

  let token = useSelector((state: RootState) => {
    return state.user.token;
  });

  const getAllClients = useCallback(() => {
    if (token) {
      getAll(token, "/clients/", setClients, () => dispatch(sessionEnd()), setError);
    }
  }, []);

  useEffect(() => {
    getAllClients();
  }, [getAllClients]);

  const clientActeam = clients.map((client: IClient) => {
    return { _id: client._id, name: client.name };
  });

  const initialDynamicValues = hosting?.custom.servers.reduce((acc, server, index) => {
    acc[`instance-${index}`] = server.instance || "";
    acc[`server-${index}`] = server.type || "";
    acc[`storage-${index}`] = server.storage || 0;
    return acc;
  }, {} as Record<`instance-${number}` | `server-${number}` | `storage-${number}`, string | number>);

  const formik = useFormik({
    initialValues: {
      // Si c'est le composant modifation du projet, reprend les infos du projet existant
      name: hosting ? hosting.name : "",
      description: hosting ? hosting.description : "",
      client: hosting ? hosting.client._id : "",
      environment: hosting ? hosting.environment : "",
      margin: hosting ? hosting.margin : 0,
      retentionTime: hosting ? hosting.backup.retentionTime : 0,
      storage: hosting ? hosting.backup.storage : 0,
      nbIP: hosting ? hosting.custom.nbIP : 0,
      ...initialDynamicValues,
    },
    validationSchema: validationSchema,
    onSubmit: (values: IFormValues) => {
      handleSubmit("all", values);
    },
  });
  const [isSwitchChecked, setIsSwitchChecked] = useState<boolean>(isSwitched);

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const switchView = () => {
    setIsSwitchChecked(!isSwitchChecked)
  };

  const switchType = () => {
    setTypeChecked(!typeChecked);
  }

  useEffect(() => {
    if(!isSwitchChecked){
      formik.setFieldValue("retentionTime", 0);
      formik.setFieldValue("storage", 0);
      formik.values.retentionTime = 0;
      formik.values.storage = 0;
    }
  }, [isSwitchChecked]);

  const [customServer, setCustomServer] = useState<number>(
    hosting ? (hosting.custom.servers.length > 0 ? hosting.custom.servers.length : 1) : 1
  );

  const handleAddServer = () => {
    setCustomServer((prev) => prev+1);
  }

  const handleRemoveServer = () => {
    if(customServer > 1) {
      formik.setFieldValue(`server-${customServer-1}`, undefined);
      formik.setFieldValue(`instance-${customServer-1}`, undefined);
      formik.setFieldValue(`storage-${customServer-1}`, undefined);
      setCustomServer((prev) => prev-1);
    }
  }

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1, width: "100%" }}>
      {!edit && (
        <Box sx={{textAlign: "center",width: "100%", display: "flex", justifyContent: "space-evenly"}}>
          <Typography variant="h6" sx={{textAlign: "center", fontWeight : !typeChecked ? "bold" : ""}}>Hébergement classique</Typography>
          <Switch {...label} onClick={() => switchType()} checked={typeChecked} /> 
          <Typography variant="h6" sx={{textAlign: "center", fontWeight : typeChecked ? "bold" : ""}}>Hébergement personnalisé</Typography>
        </Box>
      )}
      <CommonAddUpdateFields 
        formik={formik}
        clientActeam={clientActeam}
        edit={edit}
        hosting={hosting}
      />
      {typeChecked && 
        <CustomAddUpdateFields 
          handleAddServer={handleAddServer}
          handleRemoveServer={handleRemoveServer}
          customServer={customServer}
          formik={formik}
          hosting={hosting}
          typeChecked
        />
      }
      <Box><Switch {...label} onClick={() => switchView()} checked={isSwitchChecked} />{!isSwitchChecked ? "Ajouter un backup" : "Retirer le backup"}</Box>
      {isSwitchChecked && <BackupAddUpdateFields formik={formik} isSwitchChecked />}
      {error ? <Alert severity="error">{error}</Alert> : null}
      {message ? <Alert severity="success">{message}</Alert> : null}

      <Button
        onClick={() => {updateDolibarrClients(token, setError, setMessage, setClients, dispatch);}}
        fullWidth
        sx={{ mt: 3, mb: 2, border: `1px solid ${colorOrange}` }}
      >
        {"Mettre à jour clients Dolibarr"}
      </Button>
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>{hosting ? "Modifier l'hebergement" : "Ajouter un hébergement"}</Button>
    </Box>
  );
};

export default AddUpdateHosting;
